import React from 'react'
import { Grid } from "@mui/material";

import Button from '@mui/material/Button';
import './globalsearchlandingheader.scss';
import LoadMoreButton from '../../../staticcomponents/LoadMoreButton';
import Container from '@material-ui/core/Container';
import Divider from '@mui/material/Divider';
import GlobalSearchLandingTiles from '../globalsearchlandingtiles';
import GlobalSearchForm from '../globalSearchForm';

export default function GlobalSearchLandingHeader() {
    return (
        <>
            <Container maxWidth="xl" className="search-content-container">
                <Grid container className='global-search-cls'>
                    <GlobalSearchForm/>
                    <Grid container xl={12} lg={12} md={12} sm={12} xs={12} >
                        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
                            <div> 
                                <h1 className='globalsearch-result-title'>Search Results</h1>
                                <p className='globalsearch-result-desp'>93 results for benefits builder</p>
                            </div>
                        </Grid>
                    </Grid>
                    <GlobalSearchLandingTiles />
                    {/* Load More Butotn Starts */}
                <LoadMoreButton />
                {/* Load More Butotn Ends */}
                </Grid>
            </Container>
        </>
    )
}


