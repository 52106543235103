import React from 'react';
import { Grid } from '@material-ui/core';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';


export default function GlobalSearchLandingTiles() {
  return (
    <>
      <Grid container>
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card variant="outlined" className="heading-blogs">
            <CardContent className="heading-blogs-tile">
              <div className="my-header">
                <h2 className='globalsearch-title'>Heading</h2>
                <p className='tile-subhead'>[Blogs]</p>
              </div>
              <p className='globalsearch-desp'>Reflecting On The Findings In Cartus’ Latest Relocation Playbook, Our Webinar Invites Multinational Organizations Mastercard, Textron, And Westinghouse Electric Company To Share Their Experiences Of T He Current Mobility Landscape And What Solutions They Are Implementing To Meet Today’s Challenges.</p>
            </CardContent>
          </Card>
        </Grid>
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card variant="outlined" className="heading-blogs">
          <CardContent className="heading-blogs-tile">
              <div className="my-header">
                <h2 className='globalsearch-title '>Heading</h2>
                <p className='tile-subhead'>[Blogs]</p>
              </div>
              <p className='globalsearch-desp'>Reflecting On The Findings In Cartus’ Latest Relocation Playbook, Our Webinar Invites Multinational Organizations Mastercard, Textron, And Westinghouse Electric Company To Share Their Experiences Of T He Current Mobility Landscape And What Solutions They Are Implementing To Meet Today’s Challenges.</p>
            </CardContent>
          </Card>
        </Grid>
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} >
          <Card variant="outlined" className="heading-blogs">
          <CardContent className="heading-blogs-tile">
              <div className="my-header">
                <h2 className='globalsearch-title '>Heading</h2>
                <p className='tile-subhead'>[Blogs]</p>
              </div>
              <p className='globalsearch-desp'>Reflecting On The Findings In Cartus’ Latest Relocation Playbook, Our Webinar Invites Multinational Organizations Mastercard, Textron, And Westinghouse Electric Company To Share Their Experiences Of T He Current Mobility Landscape And What Solutions They Are Implementing To Meet Today’s Challenges.</p>
            </CardContent>
          </Card>
        </Grid>
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} >
          <Card variant="outlined" className="heading-blogs">
          <CardContent className="heading-blogs-tile">
              <div className="my-header">
                <h2 className='globalsearch-title '>Heading</h2>
                <p className='tile-subhead'>[Blogs]</p>
              </div>
              <p className='globalsearch-desp'>Reflecting On The Findings In Cartus’ Latest Relocation Playbook, Our Webinar Invites Multinational Organizations Mastercard, Textron, And Westinghouse Electric Company To Share Their Experiences Of T He Current Mobility Landscape And What Solutions They Are Implementing To Meet Today’s Challenges.</p>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}