import React from 'react'
import { Grid, Button, TextField, FormControl } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import './globalSearchForm.scss';
import ArrowCircleLeft from '../../../assets/ArrowCircleLeftOutlined.png';

export default function GlobalSearchForm() {
    return(
        <>
         <Grid container className='globalSearchForm'>
            <Grid item xs={12} >
               <div className="ArrowCircleLeft-search" ><img src={ArrowCircleLeft} alt="ArrowCircleLeft" /></div>
            </Grid>
            <Grid item xl={10} lg={10} md={10} sm={12} xs={12} >
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xl={8} lg={8} md={8} sm={8} xs={7}>
                    <FormControl fullWidth>
                        <TextField className='form-input form-input-icon' placeholder='Benefits Builder' />
                        <div className="search-cross-icon"><CloseIcon /></div>
                    </FormControl>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={5}>
                    <Button className='search-btn'>Search</Button>
                </Grid>
            </Grid>    
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                   &nbsp;
            </Grid>
         </Grid>
        </>
    )
}