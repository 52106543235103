import React from 'react'
import Layout from '../../staticcomponents/layout'
import './globalsearchlanding.scss'
import GlobalSearchLandingHeader from './globalsearchlandingheader'

export default function GlobalSearchLanding() {

    return (
        <>
            <Layout>
                <div className='globalsearch-landing-main'>
                    <div className='bg-capsules-eventwebinar-landing'>
                        <GlobalSearchLandingHeader />
                    </div>
                </div>
            </Layout>
        </>
    )
}


